import React from "react";
import ReactDOM from "react-dom/client"; // Correct import
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "./index.css"; // This is crucial to include Tailwind's styles
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./Routes.js"; // Import your Routes component

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoutes /> {/* Use AppRoutes here */}
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
