import React, { useEffect, useRef, useState } from "react";

const HappyCustomersComponent = () => {
  const imgArr = [
    "/assets/companies/zolvit-logo.svg",
    "/assets/companies/ekacare.png",
    "/assets/companies/epione.png",
    "/assets/companies/smallest.png",
    "/assets/companies/garbhagudi.png"
  ];

  const scrollContainer = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };
    const scroll = () => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += 1;
        if (
          scrollContainer.current.scrollLeft >=
          scrollContainer.current.scrollWidth / 2
        ) {
          scrollContainer.current.scrollLeft = 0;
        }
      }
    };

    const interval = setInterval(scroll, 20); // Adjust the speed by changing the interval time
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="bg-[rgba(246,246,246,1)] p-[5px] my-[30px] relative overflow-hidden"
      style={{
        width: isMobile ? "100%" : "",
        height: isMobile ? "100%" : "",
        marginTop: isMobile ? "16px" : "", // Changed from 1em to 16px
        marginLeft: isMobile ? "" : "",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: "23px",
          padding: "10px 0",
          fontWeight: "500",
        }}
      >
        Trusted by
      </div>
      {window.innerWidth > 760 ? (
        <>
          <div className="flex justify-around items-center my-[32px] relative">
            {" "}
            {/* Changed from 2rem to 32px */}
            {/* Gradient shadow on the left side */}
            <div className="absolute top-0 left-0 h-full w-[50px] bg-gradient-to-r from-[rgba(246,246,246,1)] to-transparent pointer-events-none z-10"></div>
            {/* Images */}
            <div className="flex justify-around items-center w-full">
              {imgArr.map((img, index) => (
                <div key={index}>
                  <img src={img} className="w-[10vw]" alt={`logo-${index}`} />
                </div>
              ))}
            </div>
            {/* Gradient shadow on the right side */}
            <div className="absolute top-0 right-0 h-full w-[50px] bg-gradient-to-l from-[rgba(246,246,246,1)] to-transparent pointer-events-none z-10"></div>
          </div>
        </>
      ) : (
        <div
          className="flex justify-center items-center my-[8px] relative overflow-hidden w-full" // Changed from 0.5rem to 8px
          ref={scrollContainer}
        >
          <div className="flex">
            {imgArr.map((img, index) => (
              <img
                key={index}
                src={img}
                className="h-[6vh] mx-[16px]" // Changed from mx-4 to mx-[16px]
                alt={`logo-${index}`}
              />
            ))}
            {/* Duplicate the images for seamless looping */}
            {imgArr.map((img, index) => (
              <img
                key={`${index}-duplicate`}
                src={img}
                className="h-[6vh] mx-[16px]" // Changed from mx-4 to mx-[16px]
                alt={`logo-duplicate-${index}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HappyCustomersComponent;
